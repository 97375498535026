import { useI18n } from 'vue-i18n'

export function useErrorHandler() {
  const i18n = useI18n()
  const responseErrorHandler = (status) => {
    const errorMessageObj = {
      500: i18n.t('errors.serverError', { server_code: status }),
      400: i18n.t('errors.clientError'),
    }
    let exactError = errorMessageObj[status]
    if (!exactError) {
      if (+status >= 400 && status < 500) {
        exactError = errorMessageObj[400]
      } else if (+status >= 500 && status < 600) {
        exactError = errorMessageObj[500]
      }
    }
    return exactError
  }
  return {
    responseErrorHandler,
  }
}
