<template>
  <div class="card">
    <div class="card-header">
      <h5 class="m-b-10">{{ $t('apiDetails') }}</h5>
    </div>
    <div class="card-block">
      <table class="table">
        <tbody>
          <tr>
            <td class="middle-align">
              <p class="mb-0"><b>{{ $t('licenseTitle') }}</b></p>
            </td>
            <td class="middle-align text-right">
              <p class="break-word-wrapper">
                <copyButton :text="license.license_key"></copyButton>
                <span>{{license.license_key}}</span>
              </p>
            </td>
          </tr>
          <tr>
            <td class="middle-align">
              <p class="mb-0"><b>{{ $t('secretKey') }}</b></p>
            </td>
            <td class="middle-align text-right">
              <div class="row align-items-center justify-content-end">
                <div class="col-auto">
                  <p class="mb-0 d-flex align-items-center break-word-wrapper">
                    <copyButton :text="license.secret_api_key"></copyButton>
                    <span>
                      {{ license.secret_api_key }}
                    </span>
                  </p>
                </div>
                <div class="col-auto">
                  <modal-wrapper>
                    <template v-slot="{ close }">
                      <confirmation-modal
                        :close="close"
                        :content="$t('confirmationSecretKey')"
                        @confirm="secretKeyResetAction(license.secret_api_key)"
                      ></confirmation-modal>
                    </template>
                    <template v-slot:trigger="{ open }">
                      <button
                        class="btn btn-primary btn-sm mt-2"
                        :disabled="isDisabledReset"
                        @click="open"
                      >{{ $t('resetSecretKey') }}</button>
                      <div class="server-error-message content mt-3" v-if="errorMessageReset.length">
                        {{ errorMessageReset }}
                        <button @click.prevent="errorMessageReset = ''" class="feather icon-x button"></button>
                      </div>
                    </template>
                  </modal-wrapper>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, onMounted, computed } from 'vue'
import { useSecretKeyReset } from '@/application/composables/resetSecretKey.js'
import { useRoute } from 'vue-router'
import { store } from '@/application/store'
import copy from 'copy-to-clipboard'

export default {
  name: 'LicenseDetailApi',
  components: {
    'modal-wrapper': defineAsyncComponent(() => import('@/application/components/modals/ModalWrapper.vue')),
    'confirmation-modal': defineAsyncComponent(() => import('@/application/components/modals/ConfirmationModal.vue')),
    copyButton: defineAsyncComponent(() => import('@/application/components/elements/CopyButton.vue')),
  },
  props: {
    license: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isDashboardReload = computed(() => store.getters.isDashboardReload)
    const {
      secretKeyResetAction,
      isDisabled: isDisabledReset,
      errorMessage: errorMessageReset,
    } = useSecretKeyReset(props.license)
    const openChat = () => {
      window.$crisp.push(['do', 'chat:open'])
    }
    const route = useRoute()

    onMounted(() => {
      if (isDashboardReload.value) {
        store.dispatch('getLicense')
          .then(() => { store.dispatch('getSavedFormsData') })
      }
    })

    return {
      secretKeyResetAction,
      isDisabledReset,
      errorMessageReset,
      openChat,
      getSenderName: (license) => `/form/sender-name?secret=${license.secret_api_key}&token=${store.getters.getToken}&license=${route.params.id}`,
      copyToClipboard: (value) => copy(value),
    }
  },
}
</script>
