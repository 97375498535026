import { ref } from 'vue'
import { useStore } from 'vuex'
import { resetSecretKey } from '@/application/services/dashboard.js'
import { useErrorHandler } from '@/application/composables/responseErrorHandler.js'

export function useSecretKeyReset(license) {
  const store = useStore()
  const isDisabled = ref(false)
  const errorMessage = ref('')
  const { responseErrorHandler } = useErrorHandler()
  const secretKeyResetAction = () => {
    const data = { license_key: license.license_key }
    isDisabled.value = true
    resetSecretKey(data)
      .then(({ data }) => {
        isDisabled.value = false
        store.commit('changeLicenseApiProperty', {
          key: 'secret_api_key',
          value: data.secret_key,
          license_key: license.license_key,
        })
      })
      .catch(err => {
        const status = err.response.status
        errorMessage.value = responseErrorHandler(status)
        isDisabled.value = false
        console.error(err)
      })
  }
  return {
    secretKeyResetAction,
    errorMessage,
    isDisabled,
  }
}
